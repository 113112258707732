import * as React from "react"
import Layout from "../components/layout"

export default function IndexPage() {
  return (
    <Layout>
      <div className="flex h-screen">
        <div className="px-4 py-8 m-auto w-full lg:w-1/2">
          <div className="text-4xl lg:text-6xl mb-4">Mors dag</div>
          <div className="text-xl">
            <br />
            <p>
              <p class="mb-2">FÖRRÄTT</p>
              <b>Toast Skagen</b>
              <br />
              med räkor, créme fraiche & majonnäs toppat med kräftstjärtar
            </p>
            <div class="text-base">alternativt</div>
            <p>
              <b>Smörstekt grön sparris</b>
              <br />
              inrullad i parmaskinka på en salladsbädd med parmesanost
            </p>

            <p class="mb-8">
              <p class="mb-2">HUVUDRÄTT</p>
              <p>
                <b>Filé Mignon Black & White</b>
                <br />
                oxfilé & fläskfilé med bearnaisesås & rödvinssås
                <br />
                <div class="text-base">alternativt</div>
                <p>
                  <b>Smörstekt rödingfilé</b>
                  <br />
                  med vitvinssås & röd tångcaviar
                </p>
              </p>
            </p>
            <p class="mb-8">
              <p class="mb-2">DESSERT</p>
              <b>Panna Cotta</b>
              <br />
              serveras med hallon
              <br />
              <div class="text-base">alternativt</div>
              <p>
                <b>Hallonsorbet</b>
                <br />
                serveras med hallon
              </p>
            </p>
            <b>två rätter: 349 kr</b>
            <br />
            <b>tre rätter: 399 kr</b>
            <br />
          </div>
        </div>
      </div>
    </Layout>
  )
}
